@charset "utf-8";

/** - - - - - - - - - - - - 
 * Utils
 - - - - - - - - - - - - */

/* - - - margin - - - */

.no-margin {margin:0!important;}
.spacer1 { margin-top: 1px; }
.spacer2 { margin-top: 2px; }
.spacer3 { margin-top: 3px; }
.spacer4 { margin-top: 4px; }
.spacer5 { margin-top: 5px; }
.spacer6 { margin-top: 6px; }
.spacer7 { margin-top: 7px; }
.spacer8 { margin-top: 8px; }
.spacer9 { margin-top: 9px; }
.spacer10 { margin-top: 10px; }
.spacer15 { margin-top: 15px; }
.spacer20 { margin-top: 20px; }
.spacer25 { margin-top: 25px; }
.spacer30 { margin-top: 30px; }
.spacer35 { margin-top: 35px; }
.spacer40 { margin-top: 40px; }
.spacer45 { margin-top: 45px; }
.spacer50 { margin-top: 50px; }
.spacer60 { margin-top: 60px; }
.spacer70 { margin-top: 70px; }
.spacer80 { margin-top: 80px; }
.spacer90 { margin-top: 90px; }
.spacer100 { margin-top: 100px; }
.spacer200 { margin-top: 200px; }
.spacer250 { margin-top: 250px; }
.spacer300 { margin-top: 300px; }
.no-padding {padding:0!important;}

/* - - - width - - - */

.w10 {width:10%!important;}
.w20 {width:20%!important;}
.w30 {width:30%!important;}
.w40 {width:40%!important;}
.w50 {width:50%!important;}
.w60 {width:60%!important;}
.w70 {width:70%!important;}
.w80 {width:80%!important;}
.w90 {width:90%!important;}
.w100 {width:100%!important;}
.wauto {width:auto!important;}

/* - - - font-size - - - */

.f6{font-size:6px;}
.f7{font-size:7px;}
.f8{font-size:8px;}
.f9{font-size:9px;}
.f10{font-size:10px;}
.f11{font-size:11px;}
.f12{font-size:12px;}
.f13{font-size:13px;}
.f14{font-size:14px;}
.f15{font-size:15px;}
.f16{font-size:16px;}
.f17{font-size:17px;}
.f18{font-size:18px;}
.f19{font-size:19px;}
.f20{font-size:20px;}
.f30{font-size:30px;}
.f40{font-size:40px;}
.f50{font-size:50px;}

/* - - - text color - - - */

.cfff {color:#fff;}
.ceee {color:#eee;}
.cddd {color:#ddd;}
.cccc {color:#ccc;}
.cbbb {color:#bbb;}
.caaa {color:#aaa;}
.c999 {color:#999;}
.c888 {color:#888;}
.c777 {color:#777;}
.c666 {color:#666;}
.c555 {color:#555;}
.c444 {color:#444;}
.c333 {color:#333;}
.c222 {color:#222;}
.c111 {color:#111;}
.c000 {color:#000;}
.cff0 {color:#ff0;}
.c3af {color:#3af;}
.must {color:#f00;}

/* - - - text deocration - - - */

.bold {font-weight:bold!important;}
.inline {display:inline!important;}
.inline-block {display:inline-block!important;}
.text-shadow {text-shadow: 0 1px 1px rgba(0,0,0,.5);}
.text-shadow-deep {text-shadow: 4px 3px 2px rgba(0,0,0,.5);}
.white-space-normal {white-space:normal;}
.break-word {word-wrap:break-word;}
.font-normal {font-weight:normal;}
.must {color:#f00; font-size:9px; margin:0 0 0 5px;}
.underline {text-decoration:underline;}
.hoverImg:hover * {opacity:0.5;}

/* - - - text positioning - - - */

.text-center {text-align:center!important;}
.text-left {text-align:left!important;}
.text-right {text-align:right!important;}
.vertical-middle {vertical-align:middle!important;}

/* - - - disabling - - - */
/* http://stackoverflow.com/questions/639815/how-to-disable-all-div-content */
.disableAll {
  pointer-events: none;
  opacity: 0.4;
}